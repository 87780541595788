/**
 * Removes white spaces from input before submit.
 * @param {string} formEl - Target form element.
 */
export function validateInput(formEl) {
  const form = document.querySelector(formEl);
  const input = form.querySelector('input[name="query"]');

  form.addEventListener("submit", (e) => {
    e.preventDefault();

    // Removes white space from input.value
    const inputValue = input.value.trim();
    input.value = inputValue;
    if (inputValue) form.submit();
  });
}
