
const getAncestors = (start, terminal) => {
  const ancestorIterator = function* (e) {
    while ((e = e.parentNode)) {
      if (e == terminal) {
        break;
      }
      yield e;
    }
  };
  return [...ancestorIterator(start), terminal];
};

const handleMenu = (event) => {
  const current = event.target;
  const item = current.closest('.navbar-item');
  const terminal = current.closest(".level_1");
  const ancestors = getAncestors(current, terminal);
  const active = document.querySelectorAll(".hover-dropdown");

  active.forEach((e) => {
    if (e != item && !ancestors.includes(e)) {
      e.classList.remove("hover-dropdown");
    }
  });
  item.classList.toggle('hover-dropdown');
};

const initDropDown = (selector = "#main-menu") => {
  const mainMenu = document.querySelector(selector);
  mainMenu.querySelectorAll(".icon-click").forEach((e) => {
    e.addEventListener("click", handleMenu);
  });
};

export default initDropDown;