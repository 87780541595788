export function openLoader() {
    console.log("openLoader");
    const el = document.getElementById("loadingForm");
    if (el){
        el.classList.toggle('is-hidden');
    }
}

export function initSearchForm(selector) {
    const form = document.getElementById(selector)
    if (form){
        form.addEventListener("submit", openLoader)
    }
    
}
