export function initSticky() {
  const stickyMenu = document.getElementById("navbar");
  const stickyOffset = stickyMenu.offsetTop;

  window.addEventListener("scroll", function (e) {
    requestAnimationFrame(() => {
      // TODO: change pageYOffset to ScrollY
      if (window.pageYOffset > stickyOffset) {
        stickyMenu.classList.add("is-fixed-top");
      } else {
        stickyMenu.classList.remove("is-fixed-top");
      }
    });
  });
}