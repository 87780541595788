// TODO: change this import to modulePreload.polyfill
import "vite/modulepreload-polyfill";
import "./scss/style.scss";
import { initBurger } from "./js/burger";
import { initSlide } from "./js/slider";
import { initNotes } from "./js/slider-notes";
import { initSticky } from "./js/menu-sticky";
import { initSearchForm } from "./js/benefit-search";
import { initVideos, resizeVideos } from "./js/video";
import initDropDown from "./js/menu-dropdown";
import { validateInput } from "./js/validate-input";

function initSite() {
  initSlide(".slider");
  initNotes();
  initSticky();
  initBurger(".navbar-burger");
  initDropDown();
  initVideos(".block-video iframe");
  resizeVideos(".block-video iframe");
  initSearchForm("benefit-search-form");
  validateInput(".search-form");
}

document.addEventListener("DOMContentLoaded", initSite, false);
